import { default as cookiesaksX7kSjcYMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookies8mOp4mSI3wMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as indexCPlSPhyJlFMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legal1AkZIBvvbgMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45abouty0Auz6qEiOMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45feesKQzxzrBxRyMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorskMcnAZfLsqMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersxqKdZdch4GMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersanPngWlJudMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as portfolio_45newsdRYUGdEva0Meta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news.vue?macro=true";
import { default as portfolio_45partnersYjUTAKT1KxMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacyd3uBcXdQo3Meta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as indexXhVGVaJmLcMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue?macro=true";
import { default as legalg8aNSSyweXMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutyBCuXwWL1lMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45feesuwJDqZXbpBMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsbuw5al03O7Meta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersgEknV7rqghMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersLK0wqciRujMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as portfolio_45news7UZsZEbSRWMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news.vue?macro=true";
import { default as portfolio_45partnersCXYte2NHhTMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacyBqmpum9JPiMeta } from "/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookiesaksX7kSjcYMeta?.name ?? "cookies",
    path: cookiesaksX7kSjcYMeta?.path ?? "/cookies",
    meta: cookiesaksX7kSjcYMeta || {},
    alias: cookiesaksX7kSjcYMeta?.alias || [],
    redirect: cookiesaksX7kSjcYMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies8mOp4mSI3wMeta?.name ?? "es-cookies",
    path: cookies8mOp4mSI3wMeta?.path ?? "/es/cookies",
    meta: cookies8mOp4mSI3wMeta || {},
    alias: cookies8mOp4mSI3wMeta?.alias || [],
    redirect: cookies8mOp4mSI3wMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexCPlSPhyJlFMeta?.name ?? "es",
    path: indexCPlSPhyJlFMeta?.path ?? "/es",
    meta: indexCPlSPhyJlFMeta || {},
    alias: indexCPlSPhyJlFMeta?.alias || [],
    redirect: indexCPlSPhyJlFMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legal1AkZIBvvbgMeta?.name ?? "es-legal",
    path: legal1AkZIBvvbgMeta?.path ?? "/es/legal",
    meta: legal1AkZIBvvbgMeta || {},
    alias: legal1AkZIBvvbgMeta?.alias || [],
    redirect: legal1AkZIBvvbgMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45abouty0Auz6qEiOMeta?.name ?? "es-portfolio-about",
    path: portfolio_45abouty0Auz6qEiOMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45abouty0Auz6qEiOMeta || {},
    alias: portfolio_45abouty0Auz6qEiOMeta?.alias || [],
    redirect: portfolio_45abouty0Auz6qEiOMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesKQzxzrBxRyMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45feesKQzxzrBxRyMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45feesKQzxzrBxRyMeta || {},
    alias: portfolio_45feesKQzxzrBxRyMeta?.alias || [],
    redirect: portfolio_45feesKQzxzrBxRyMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorskMcnAZfLsqMeta?.name ?? "es-portfolio-investors",
    path: portfolio_45investorskMcnAZfLsqMeta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investorskMcnAZfLsqMeta || {},
    alias: portfolio_45investorskMcnAZfLsqMeta?.alias || [],
    redirect: portfolio_45investorskMcnAZfLsqMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersxqKdZdch4GMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuersxqKdZdch4GMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuersxqKdZdch4GMeta || {},
    alias: portfolio_45issuersxqKdZdch4GMeta?.alias || [],
    redirect: portfolio_45issuersxqKdZdch4GMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersanPngWlJudMeta?.name ?? "es-portfolio-members",
    path: portfolio_45membersanPngWlJudMeta?.path ?? "/es/portfolio-members",
    meta: portfolio_45membersanPngWlJudMeta || {},
    alias: portfolio_45membersanPngWlJudMeta?.alias || [],
    redirect: portfolio_45membersanPngWlJudMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45newsdRYUGdEva0Meta?.name ?? "es-portfolio-news",
    path: portfolio_45newsdRYUGdEva0Meta?.path ?? "/es/portfolio-news",
    meta: portfolio_45newsdRYUGdEva0Meta || {},
    alias: portfolio_45newsdRYUGdEva0Meta?.alias || [],
    redirect: portfolio_45newsdRYUGdEva0Meta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersYjUTAKT1KxMeta?.name ?? "es-portfolio-partners",
    path: portfolio_45partnersYjUTAKT1KxMeta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partnersYjUTAKT1KxMeta || {},
    alias: portfolio_45partnersYjUTAKT1KxMeta?.alias || [],
    redirect: portfolio_45partnersYjUTAKT1KxMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyd3uBcXdQo3Meta?.name ?? "es-privacy",
    path: privacyd3uBcXdQo3Meta?.path ?? "/es/privacy",
    meta: privacyd3uBcXdQo3Meta || {},
    alias: privacyd3uBcXdQo3Meta?.alias || [],
    redirect: privacyd3uBcXdQo3Meta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexXhVGVaJmLcMeta?.name ?? "index",
    path: indexXhVGVaJmLcMeta?.path ?? "/",
    meta: indexXhVGVaJmLcMeta || {},
    alias: indexXhVGVaJmLcMeta?.alias || [],
    redirect: indexXhVGVaJmLcMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalg8aNSSyweXMeta?.name ?? "legal",
    path: legalg8aNSSyweXMeta?.path ?? "/legal",
    meta: legalg8aNSSyweXMeta || {},
    alias: legalg8aNSSyweXMeta?.alias || [],
    redirect: legalg8aNSSyweXMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutyBCuXwWL1lMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutyBCuXwWL1lMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutyBCuXwWL1lMeta || {},
    alias: portfolio_45aboutyBCuXwWL1lMeta?.alias || [],
    redirect: portfolio_45aboutyBCuXwWL1lMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesuwJDqZXbpBMeta?.name ?? "portfolio-fees",
    path: portfolio_45feesuwJDqZXbpBMeta?.path ?? "/portfolio-fees",
    meta: portfolio_45feesuwJDqZXbpBMeta || {},
    alias: portfolio_45feesuwJDqZXbpBMeta?.alias || [],
    redirect: portfolio_45feesuwJDqZXbpBMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsbuw5al03O7Meta?.name ?? "portfolio-investors",
    path: portfolio_45investorsbuw5al03O7Meta?.path ?? "/portfolio-investors",
    meta: portfolio_45investorsbuw5al03O7Meta || {},
    alias: portfolio_45investorsbuw5al03O7Meta?.alias || [],
    redirect: portfolio_45investorsbuw5al03O7Meta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersgEknV7rqghMeta?.name ?? "portfolio-issuers",
    path: portfolio_45issuersgEknV7rqghMeta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuersgEknV7rqghMeta || {},
    alias: portfolio_45issuersgEknV7rqghMeta?.alias || [],
    redirect: portfolio_45issuersgEknV7rqghMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersLK0wqciRujMeta?.name ?? "portfolio-members",
    path: portfolio_45membersLK0wqciRujMeta?.path ?? "/portfolio-members",
    meta: portfolio_45membersLK0wqciRujMeta || {},
    alias: portfolio_45membersLK0wqciRujMeta?.alias || [],
    redirect: portfolio_45membersLK0wqciRujMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45news7UZsZEbSRWMeta?.name ?? "portfolio-news",
    path: portfolio_45news7UZsZEbSRWMeta?.path ?? "/portfolio-news",
    meta: portfolio_45news7UZsZEbSRWMeta || {},
    alias: portfolio_45news7UZsZEbSRWMeta?.alias || [],
    redirect: portfolio_45news7UZsZEbSRWMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersCXYte2NHhTMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersCXYte2NHhTMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersCXYte2NHhTMeta || {},
    alias: portfolio_45partnersCXYte2NHhTMeta?.alias || [],
    redirect: portfolio_45partnersCXYte2NHhTMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyBqmpum9JPiMeta?.name ?? "privacy",
    path: privacyBqmpum9JPiMeta?.path ?? "/privacy",
    meta: privacyBqmpum9JPiMeta || {},
    alias: privacyBqmpum9JPiMeta?.alias || [],
    redirect: privacyBqmpum9JPiMeta?.redirect,
    component: () => import("/codebuild/output/src1899058071/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]